import React, { useState } from "react";
import { useStaticQuery, graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { MenuIcon, XIcon } from "@heroicons/react/outline";
import Link from "./link";

const Layout = ({ pageTitle, children }) => {
    const [isOpen, setIsOpen] = useState(false);

    const data = useStaticQuery(graphql`
        query {
            site {
                siteMetadata {
                    title
                    copyrightYear
                }
            }
        }
    `);

    const openMenu = ev => {
        ev.stopPropagation();
        setIsOpen(true);
    };

    const closeMenu = ev => {
        ev.stopPropagation();
        setIsOpen(false);
    };

    const getLink = (text, link) => (
        <Link to={"/" + link} className="inline-block w-full md:w-auto md:inline py-1 md:py-0 my-1 md:my-1 text-center bg-white bg-opacity-10 md:bg-none md:bg-opacity-0 hover:font-bold" onClick={() => setIsOpen(false)}>{text}</Link>
    );

    return (
        <>
            <title>{data.site.siteMetadata.title} - {pageTitle}</title>
            <div className="flex flex-col min-h-screen">
                <div className="overflow-hidden bg-main-dark sticky top-0 z-40 min-w-full">
                    <div className="max-w-5xl mx-auto flex flex-row flex-wrap px-2 py-4 md:space-x-2 items-center">
                        <Link to="/" className="mr-2">
                            <StaticImage alt="" src="../images/logo.png" loading="eager" placeholder="none" className="h-8 w-8 md:h-auto md:w-auto" />
                        </Link>
                        <header className="text-2xl md:text-4xl md:pl-2 font-bold flex-grow">
                            <Link to="/" className="md:align-text-bottom">
                                {data.site.siteMetadata.title}
                            </Link>
                        </header>
                        <button className={"w-8 h-8 md:hidden outline-none" + (isOpen ? " hidden" : " block")} onClick={openMenu}><MenuIcon className="h-7 w-7 inline-block align-middle" /></button>
                        <button className={"w-8 h-8 md:hidden outline-none" + (isOpen ? " block" : " hidden")} onClick={closeMenu}><XIcon className="h-7 w-7 inline-block align-middle" /></button>

                        <div className={"flex-none w-full md:w-auto md:block pt-4 md:pt-0 md:pr-3 xl:pr-0" + (isOpen ? "" : " hidden")}>
                            <ul className="md:flex md:flex-row md:space-x-6 items-center text-lg">
                                <li>{getLink("Home", "")}</li>
                                <li>{getLink("Work", "work")}</li>
                                <li>{getLink("Contact", "contact")}</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="flex-grow" onClick={closeMenu}>
                    {children}
                </div>

                <footer className="w-full bg-gray-100 p-4 text-sm text-center mt-6" onClick={closeMenu}>
                    Copyright &copy; <Link to="/contact">Aleš Krištof</Link>, {data.site.siteMetadata.copyrightYear}
                </footer>
            </div>
        </>
    );
};

export default Layout;
