import * as React from "react";
import { Link as GatsbyLink } from "gatsby";

const Link = ({ to, className, children, umami_event=null }) => {

    const calcClassName = className !== undefined ? className : "hover:font-bold"; // hover:underline

    const trackClick = () => {
        // eslint-disable-next-line no-undef
        umami.track(umami_event);
    };

    return (
        <GatsbyLink to={to} className={calcClassName} onClick={umami_event !== null ? trackClick : undefined}>{children}</GatsbyLink>
    );
};

export default Link;
